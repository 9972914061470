<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>

    <b-card
      no-body
    >
      <b-card-body>
        <div class="h4 mb-1">
          ประวัติใช้งานสมาชิก
        </div>

        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-start py-50">
            <div
              class="btn-disable"
            >
              วันที่สมัคร(จาก)
            </div>
            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
            />
          </div>

          <div class="d-flex align-items-start py-50">
            <div
              class="btn-disable"
            >
              วันที่สมัคร(ถึง)
            </div>
            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
            />
          </div>

          <div class="d-flex align-items-start py-50">
            <div
              class="btn-disable"
            >
              หัวข้อ
            </div>
            <b-form-select
              v-model="topic"
              :options="topic_option"
              placeholder="เลือกหัวข้อที่จะค้นหา"
            />
          </div>

          <div class="d-flex align-items-start py-50">
            <div
              class="btn-disable"
            >
              ประเภท
            </div>
            <b-form-select
              v-model="search_type"
              :options="search_type_option"
              placeholder="เลือกประเภทที่จะค้นหา"
            />
          </div>

          <div class="d-flex align-items-start py-50">
            <div
              class="btn-disable"
            >
              คำค้นหา
            </div>
            <input
              id="username"
              v-model="search_val"
              :disabled="!search_type"
              type="text"
              class="form-control"
              placeholder="กรอกข้อมูลที่ต้องการค้นหา"
            >
          </div>

          <div class="d-flex align-items-start py-50">
            <button
              class="btn btn-primary ml-lg-25"
              @click="GetData()"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(country)="data">
          <div>
            <div>
              ประเทศ :
              <b-badge
                variant="light-primary"
              >
                {{ data.item.country }}
              </b-badge>
            </div>
            <div>
              ภูมิภาค :
              <b-badge
                variant="light-success"
              >
                {{ data.item.region }}
              </b-badge>
            </div>
            <div>
              เมือง :
              <b-badge
                variant="light-warning"
              >
                {{ data.item.city }}
              </b-badge>
            </div>
          </div>
        </template>
        <template #cell(topic)="data">
          <div>
            <b-badge
              v-if="data.item.topic === 'login'"
              variant="success"
            >
              {{ data.item.topic }}
            </b-badge>
            <b-badge
              v-else-if="data.item.topic === 'logout'"
              variant="danger"
            >
              {{ data.item.topic }}
            </b-badge>
            <b-badge
              v-else-if="data.item.topic === 'play'"
              variant="warning"
            >
              {{ data.item.topic }}
            </b-badge>
            <b-badge
              v-else
              variant="light-secondary"
            >
              {{ data.item.status }}
            </b-badge>
          </div>
          <b-badge
            v-if="data.item.topic === 'play'"
            variant="light-primary"
          >
            {{ data.item.gamename }}
          </b-badge>
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <template #cell(location)="data">
          <p v-if="data.item.lat">
            Latitude: {{ data.item.lat }}
          </p>
          <p v-if="data.item.lon">
            Longitude: {{ data.item.lon }}
          </p>
        </template>
        <template #cell(org)="data">
          org: {{ data.item.org }}/ ISP: {{ data.item.isp }}
        </template>
        <template #cell(continent)="data">
          <div class="text-center">
            <b-avatar
              v-if="data.item.country_flag"
              size="sm"
              :src="data.item.country_flag"
            />
            <b-badge
              variant="light-dark"
            >
              {{ data.item.continent }}
            </b-badge>
          </div>

        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover.v-info
              title="รายละเอียด"
              variant="gradient-info"
              size="sm"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
            >
              <i class="fas fa-address-card" />
            </b-button>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  // BRow, BCol,
  // BInputGroupPrepend, BModal,
  BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BBadge,
  // BInputGroup, BFormInput,
  BButton, BCardBody, VBToggle, BOverlay, BIconController, VBTooltip,
  // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // BFormCheckbox,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'เบอร์โทร' },
      ],
      topic_option: [
        { value: 'login', text: 'เข้าสู่ระบบ' },
        { value: 'logout', text: 'ออกจากระบบ' },
        { value: 'play', text: 'เข้าเกมส์' },
      ],
      topic: null,
      search_type: null,
      search_val: null,
      show: true,
      prolist: [],
      pro_id: null,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'topic', label: 'หัวข้อ' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'tel', label: 'เบอร์โทร' },
        { key: 'ip', label: 'ip' },
        { key: 'continent', label: 'ทวีป' },
        { key: 'country', label: 'ภูมิภาค' },
        { key: 'location', label: 'สถานที่' },
        { key: 'org', label: 'org' },
        { key: 'created_at', label: 'เวลา', formatter: value => (value ? moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss') : '') },
        { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.GetData()
  },
  methods: {
    async GetData() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        topic: this.topic,
      }
      await this.$http
        .get('/admin/loguser', { params })
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.ResData
      this.totalRows = filteredItems.total
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
